import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 758 });
  const [explore, setExplore] = useState(true);
  const [blog, setblog] = useState(true);
  const [legal, setlegal] = useState(true);

  const connectedIcons = () => {
    return (
      <>
        <p className={styles.head2}>STAY CONNECTED</p>
        <div className={styles.icons}>
          <a
            target="_blank"
            style={{ color: "white" }}
            rel="noopener noreferrer"
            href="https://www.instagram.com/bdittoapp/"
          >
            <img
              className={styles.icon}
              src="/icons/socialIcons/instagram.svg"
            />
          </a>
          <a
            target="_blank"
            style={{ color: "white" }}
            rel="noopener noreferrer"
            href="https://twitter.com/bdittoapp"
          >
            <img className={styles.icon} src="/icons/socialIcons/twitter.svg" />
          </a>
          <a
            target="_blank"
            style={{ color: "white" }}
            rel="noopener noreferrer"
            href="https://www.facebook.com/Bditto-105470858253412/"
          >
            <img src="/icons/socialIcons/facebook.svg" />
          </a>
          <a
            target="_blank"
            style={{ color: "white" }}
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/bditto"
          >
            <img
              className={styles.icon}
              src="/icons/socialIcons/linkedIn.svg"
            />
          </a>
        </div>
      </>
    );
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.cont}>
          <div className={styles.info}>
            <p className={styles.brand}>
              <img src="logoMain.svg" alt="logo.svg" />
            </p>
            {!isMobile && connectedIcons()}
          </div>
          <div className={styles.options}>
            <div className={styles.options1}>
              <div className={styles.option}>
                <p className={styles.class}>
                  LEGAL{" "}
                  {isMobile && (
                    <>
                      {" "}
                      {legal ? (
                        <img
                          src="/icons/UpCheveron.svg"
                          onClick={() => setlegal(false)}
                          style={{ marginLeft: "auto", marginTop: "-0.2rem" }}
                        />
                      ) : (
                        <img
                          src="/icons/DownChevron.svg"
                          onClick={() => setlegal(true)}
                          style={{ marginLeft: "auto", marginTop: "-0.2rem" }}
                        />
                      )}{" "}
                    </>
                  )}{" "}
                </p>
                {legal && (
                  <ul>
                    <li className={styles.links}>
                      <Link to="/privacy">Privacy</Link>
                    </li>
                    <li className={styles.links}>
                      <Link to="/userAgreement">User Agreement</Link>
                    </li>
                    <li className={styles.links}>
                      <Link to="/contentPolicy">Content Policy</Link>
                    </li>
                  </ul>
                )}
              </div>

              <div className={styles.option}>
                <p className={styles.class}>
                  EXPLORE{" "}
                  {isMobile && (
                    <>
                      {" "}
                      {explore ? (
                        <img
                          src="/icons/UpCheveron.svg"
                          onClick={() => setExplore(false)}
                          style={{ marginLeft: "auto", marginTop: "-0.2rem" }}
                        />
                      ) : (
                        <img
                          src="/icons/DownChevron.svg"
                          onClick={() => setExplore(true)}
                          style={{ marginLeft: "auto", marginTop: "-0.2rem" }}
                        />
                      )}{" "}
                    </>
                  )}
                </p>
                {explore && (
                  <ul>
                    <li className={styles.links}>
                      <Link
                        to={{
                          pathname: "/",
                          state: { block: "home" },
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className={styles.links}>
                      <Link to="/aboutus">About us</Link>
                    </li>
                    {/* <li className={styles.links}>
                    <Link to="/">
                     How It Works
                    </Link>
                  </li> */}
                    <li className={styles.links}>
                      <Link to="/lab">Bditto Labs</Link>
                    </li>
                    <li className={styles.links}>
                      <Link to="/get-in-touch">Contact sales</Link>
                    </li>
                    <li className={styles.links}>
                      <Link to="/">FAQs</Link>
                    </li>
                  </ul>
                )}
              </div>

              {/* <div className={styles.option}>
                <p className={styles.class}>
               BLOG {isMobile && <> {blog ? 
                  <img src="/icons/UpCheveron.svg" onClick={()=> setblog(false)} style={{marginLeft:'auto',marginTop:'-0.2rem'}}/>
                  :
                <img src="/icons/DownChevron.svg" onClick={()=> setblog(true)} style={{marginLeft:'auto',marginTop:'-0.2rem'}}/>
             } </> }</p>
              {blog && <ul>
                  <li className={styles.links}>
                    <Link to="/">
                    Productivity Hacks
                    </Link>
                  </li>
                  <li className={styles.links}>
                    <Link to="/">
                    Productivity Hacks
                    </Link>
                  </li>
                  <li className={styles.links}>
                    <Link to="/">
                    WFH research
                    </Link>
                  </li>
                </ul>}
              </div> */}
            </div>
          </div>
          {isMobile && (
            <div style={{ marginLeft: "2rem" }}> {connectedIcons()}</div>
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.links}>
          <div className={styles.iconsLeft}>
            <a href="mailto:hello@bditto.com" className={styles.iconsLeft}>
              <img src="/icons/mail.svg" />
              <span className={styles.addresses}>hello@bditto.com</span>
            </a>
          </div>
          <div className={styles.iconsLeft}>
            <a href="tel:+41791562333" className={styles.iconsLeft}>
              <img src="/icons/phone.svg" />
              <spa className={styles.addresses}> +44 7894282761</spa>
            </a>
          </div>
          <div className={styles.iconsLeft}>
            <img src="/icons/home.svg" />
            <span className={styles.addresses}>
              Suite-B, 42-44, Bishopsgate, <br /> London EC2N 4AH, <br />
              United Kingdom.
            </span>
          </div>
        </div>
        <p className={styles.copy}>
          {`© Bditto`} {new Date().getFullYear()},All Rights Reserved{" "}
        </p>
      </div>
    </>
  );
};

export default Footer;
