
import { useHistory } from "react-router-dom";
import VioletBorderButton from "../../Buttons/Violet_Border_Button/Violet_Border_Button";
import classes from "./Small_Blog_Card.module.css";


function Small_Blog_Card (props) {
    const history = useHistory();
    const {media,tags,caption,id} = props
    return (
      <div className={classes.Small_Blog_Card}>
          <div className={classes.image_box}>
            <img className={classes.image} src={media} alt=""></img>
          </div>
          <div className={classes.text_box} >
                <div className={classes.hashtag_datebox}>
                    <p>
                    {tags.map(i =>(
                        <span>#{i}</span>
                    ))}
                    </p>
                <p className={classes.date}>{new Date(props.date).toDateString()}</p>
                </div>
                <div>
                    <h2 className={classes.title}>
                         {props.title}
                    </h2>
                </div>
                <div>
                    <h3 className={classes.caption} dangerouslySetInnerHTML={{ __html:caption }}>
                        
                    </h3>
                </div>
                <div className={classes.button_box} onClick={()=> history.push(`/blogs/${id}`)}>
                    <VioletBorderButton>Read More &#8594;</VioletBorderButton>
                </div>
          </div>
      </div>
    );
}


export default Small_Blog_Card;