import React, { useState } from "react";
// import { SMTPClient } from 'emailjs';
import { withStyles } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Violet_Button from "../../Buttons/Violet_Button/Violet_Button";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import sgMail from "@sendgrid/mail";
import styles from "./ContactForm.module.css";

const GreyCheckbox = withStyles({
  root: {
    color: "#7F89BE",
    "&$checked": {
      color: "#7F89BE",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#7F89BE",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#7F89BE",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#7F89BE",
        color: "#7F89BE",
      },
      "&:hover fieldset": {
        borderColor: "#7F89BE",
        color: "#7F89BE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#7F89BE",
        color: "#7F89BE",
      },
      "& .MuiFormLabel-root , .MuiInputBase-root": {
        color: "#7F89BE",
      },
    },
  },
})(TextField);

const ContactForm = ({ leftHeading }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [size, setSize] = useState(0);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setSize(event.target.value);
  };

  sgMail.setApiKey(
    `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`
  );

  const msg = {
    firstname: firstName,
    lastname: lastName,
    to: "rajat2411gupta@gmail.com",
    from: email,
    message: message,
    phone: Phone,
    size: size,
    checked: checked,
    email: email,
  };

  const sendEmail = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      Phone === 0 ||
      message === "" ||
      size === 0 ||
      !checked
    ) {
      console.log("eroororororor");
      return;
    }
    console.log("Connetced");

    try {
      await sgMail.send(msg);
      console.log("send");
    } catch (error) {
      console.error(error);
    }
  };

  const isMobile = useMediaQuery({ query: "(max-device-width: 868px)" });
  const firstField = {
    color: "#7F89BE",
    width: isMobile ? "100%" : "50%",
    display: "flex",
    flexDirection: "column",
    marginBottom: isMobile ? "0.9rem" : "0",
    marginTop: isMobile ? "0.4rem" : "0",
  };
  const secondField = {
    marginLeft: isMobile ? "0rem" : "3rem",
    width: isMobile ? "100%" : "50%",
    display: "flex",
    flexDirection: "column",
    marginBottom: isMobile ? "0.4rem" : "0",
  };
  return (
    <div className={styles.formContainer}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <img src="/logoMain.svg" alt="logo.png" style={{ height: "50px" }} />
        </div>

        <div className={styles.heading}>
          {!leftHeading ? (
            <h1>
              Excited <span className={styles.to}>to</span>{" "}
              <span className={styles.meet}>meet</span> one of Bditto’s experts?
            </h1>
          ) : (
            <h1>
              {" "}
              Schedule your <span className={styles.meet}>Bditto plan </span>
              now.
            </h1>
          )}
        </div>

        <div className={styles.links}>
          <div className={styles.iconsLeft}>
            <a href="mailto:hello@bditto.com" className={styles.iconsLeft}>
              <img src="/icons/mail.svg" />
              <span className={styles.addresses}>hello@bditto.com</span>
            </a>
          </div>

          <div className={styles.iconsLeft}>
            <a href="tel:+41791562333" className={styles.iconsLeft}>
              <img src="/icons/phone.svg" />
              <spa className={styles.addresses}> +44 7894282761</spa>
            </a>
          </div>

          <div className={styles.iconsLeft}>
            <img src="/icons/home.svg" />
            <span className={styles.addresses}>
              {" "}
              Suite-B, 42-44, Bishopsgate, <br /> London EC2N 4AH, <br />
              United Kingdom.
            </span>
          </div>
        </div>
        <div className={styles.icons}>
          <a
            href="https://www.facebook.com/Bditto-105470858253412/"
            target="_blank"
          >
            <img src="/icons/socialIcons/facebook.svg" />
          </a>
          <a href="https://twitter.com/bdittoapp" target="_blank">
            <img className={styles.icon} src="/icons/socialIcons/twitter.svg" />
          </a>
          <a href="https://www.instagram.com/bdittoapp/" target="_blank">
            <img
              className={styles.icon}
              src="/icons/socialIcons/instagram.svg"
            />
          </a>
          <a href="https://www.linkedin.com/company/bditto/" target="_blank">
            <img
              className={styles.icon}
              src="/icons/socialIcons/linkedIn.svg"
            />
          </a>
        </div>
      </div>
      <div className={styles.rightForm}>
        <div className={styles.head}>
          <img
            src={!leftHeading ? "/icons/hand.svg" : "/icons/tag.svg"}
            style={{ marginRight: "10px" }}
          />
          {!leftHeading ? "Get in Touch" : "Pricing Info"}
        </div>

        <h5 className={styles.sectionHead}>Personal</h5>

        <div className={styles.fields}>
          <CustomInput
            label="First Name*"
            autoComplete="invalidField"
            variant="outlined"
            color="primary"
            style={firstField}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <CustomInput
            label="Last Name*"
            autoComplete="invalidField"
            variant="outlined"
            color="primary"
            style={secondField}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <div className={styles.fields}>
          <CustomInput
            label="Work email address*"
            autoComplete="invalidField"
            variant="outlined"
            color="primary"
            style={firstField}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <CustomInput
            label="Phone Number*"
            autoComplete="invalidField"
            variant="outlined"
            color="primary"
            style={secondField}
            helperText="Phone should only have numeric"
            value={Phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>

        <h5 className={styles.sectionHead}>Company Info</h5>

        <div className={styles.fields}>
          <FormControl
            variant="outlined"
            style={{
              color: "#7F89BE",
              width: isMobile ? "100%" : "46%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Company Size
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={size}
              onChange={handleChange}
              label="Company Size"
            >
              <MenuItem style={{ color: "#7F89BE" }} value={0}>
                <em>None</em>
              </MenuItem>
              <MenuItem style={{ color: "#7F89BE" }} value={10}>
                0-10
              </MenuItem>
              <MenuItem style={{ color: "#7F89BE" }} value={50}>
                11-50
              </MenuItem>
              <MenuItem style={{ color: "#7F89BE" }} value={100}>
                51-100
              </MenuItem>
              <MenuItem style={{ color: "#7F89BE" }} value={500}>
                100-500
              </MenuItem>
              <MenuItem style={{ color: "#7F89BE" }} value={2000}>
                501-2000
              </MenuItem>
              <MenuItem style={{ color: "#7F89BE" }} value={2001}>
                2000+
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <CustomInput
          style={{ marginTop: "1rem" }}
          id="outlined-multiline-static"
          label="How can Bditto help your organization?(Optional)"
          multiline
          rows={4}
          color="primary"
          variant="outlined"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />

        <FormControlLabel
          style={{
            color: "var(--secondary)",
            marginTop: "1rem",
            width: "100%",
          }}
          control={<GreyCheckbox name="checkedG" />}
          label="I have read and agree to the User Agreement, Privacy Policy, and Cookie Policy."
          value={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />

        <div
          style={{
            marginTop: "1.5rem",
            margin: isMobile ? "2rem auto" : "1rem 0",
          }}
        >
          <Violet_Button
            className={styles.heading1_button}
            sendEmail={sendEmail}
            onClick={sendEmail}
          >
            Schedule a meet
            <img
              src="/icons/callender.svg"
              style={{ marginBottom: "-0.2rem", marginLeft: "0.3rem" }}
            />
          </Violet_Button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
